<template>
  <div>
    <vx-card>
      <!--Add Story Start Section-->
      <h2>Your childs story</h2>

      <div v-if="showItem == 'start'">
        <p>Tell your child's story by answering a series of questions. The questionnaire will take approximately 20
          minutes.</p>

        <vs-button color="primary" type="filled" class="mt-10" @click="showItem = 0">
          start
        </vs-button>
      </div>
      <!--Add Story-->
      <div v-else>
        <div class="child-story-steps">
          <ul>
            <li v-for="index in stories.childstories.length+1" :key="index" :class="stepClass(index-1)"><span>{{
                index
              }}</span></li>
          </ul>
        </div>
        <div v-if="stories" class="story-form">
          <div v-for="(story,index) in stories.childstories" :key="story._id" class="mb-5">

            <div v-show="index === currentIndex">
             <span class="story-instruction">{{ index + 1 + '. ' + story.instruction }}</span>
              <div v-if="story.answerType == 'text'" class="mt-5">
                <vs-textarea counter="600" :counter-danger.sync="counterDanger" v-model="answers[index].story"
                             class="mt-2"/>
              </div>
              <div v-else >
                <div class="w-full mb-5 story-img">
                  <template v-if="answers[index].dataImg" >
                    <img :src="answers[index].dataImg">
                    <div class="modify-img flex my-5">
                      <input
                        type="file"
                        class="hidden"
                        :ref="'uploadImgInput' + story._id"
                        @change="updateCurrImg($event,index)"
                        accept="image/*"
                      />
                      <vs-button href="#" @click="displayRef('uploadImgInput' + story._id)">Update Image</vs-button>
                      <vs-button
                        color="danger"
                        class="ml-4"
                        @click="answers[index].dataImg = null; answers[index].story = null"
                      >Remove Image
                      </vs-button>
                    </div>
                  </template>

                  <div class="upload-img mt-5" v-if="!answers[index].dataImg">
                    <input
                      type="file"
                      class="hidden"
                      :ref="'uploadImgInput' + story._id"
                      @change="updateCurrImg($event,index)"
                      accept="image/*"
                    />
                    <vs-button type="border" @click="displayRef('uploadImgInput' + story._id)">Upload Photo</vs-button>
                  </div>
                </div>
              </div>
              <span v-if="error" style="color: red">{{ error }}</span>
            </div>
          </div>
          <div v-if="currentIndex === (stories.childstories.length)" class="mt-5">
            <div class="">
              <div v-for="review in answers" :key="review._id" class="mb-5 ">
                <h4 class="mb-2 text-black"><strong>{{ review.order + '. ' + review.instruction }}</strong></h4>
                <p v-if="review.type === 'text'" class="mb-2">{{ review.story }}</p>
                <p v-if="review.type === 'image'" class="story-img"><img :src="review.dataImg"></p>
              </div>
            </div>
          </div>
          <div class="flex" style="margin-top: 45px">

            <vs-button color="primary" type="border" @click="previous" v-show="currentIndex !== 0">
              Previous
            </vs-button>
            <vs-button color="primary" type="filled" @click="next" v-show="currentIndex !== (stories.childstories.length)" class="ml-auto">
              Next
            </vs-button>
            <vs-button color="primary" type="filled" @click="saveStory" v-show="currentIndex === (stories.childstories.length)" class="ml-auto">
              Submit
            </vs-button>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      showItem: 'start',
      activeIndex: 0,
      stories: {},
      answers: [],
      dataImg: "",
      storyId: null,
      error: null,
      currentIndex: 0,
      counterDanger: false
    }
  },
  methods: {
    ...mapActions("childrenStories", [
        "fetchParentChildStoryPresetByCenterId",
        "storeChildStoryForParent"
      ]
    ),
    stepClass(index) {
      if (index < this.currentIndex) {
        return "step-complete";
      } else if (index === this.currentIndex) {
        return "step-complete last";
      } else {
        return "";
      }
    },
    async getChildStoriesPresets() {
      await this.fetchParentChildStoryPresetByCenterId(this.child.learningCenterId).then(async (response) => {
        let self = this;
        self.stories = response.data.data[0];
        await _.forEach(self.stories.childstories, function (value, key) {
          const id = value._id;
          const obj = {
            presetId: id,
            order: key + 1,
            type: value.answerType[0],
            instruction: value.instruction,
            story: null,
            dataImg: null
          }
          self.answers.push(obj);
        });
      });
    },
    previous() {
      this.error = null;
      this.currentIndex--;
    },
    next() {
      this.error = null;
      if (
        (this.answers[this.currentIndex].story !== null
          && this.answers[this.currentIndex].story !== "") ||
        (this.answers[this.currentIndex].dataImg != null
          && this.answers[this.currentIndex].dataImg !== "")
      ) {
        this.currentIndex++;
      } else {
        this.error = "Please fill the field.";
      }

    },
    displayRef(ref) {
      this.$refs[ref][0].click()
    },
    saveStory() {
      this.error = null;
      this.$vs.loading();

      let data = new FormData();
      let self = this;
      data.append(`contributorId`, this.parent._id);
      data.append(`childId`, self.childId);
      data.append(`fullName`, self.parent.fullName);

      _.forEach(self.answers, function (value, key) {
        data.append(`presetId[${key}]`, value.presetId);
        data.append(`order[${key}]`, value.order);
        data.append(`type[${key}]`, value.type);
        data.append(`instruction[${key}]`, value.instruction);
        data.append(`story[${key}]`, value.story);
      });
      this.storeChildStoryForParent(data)
        .then((res) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Success',
            text: "Story added successfully",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          this.$emit('showList');
        })
        .catch((err) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Failure',
            text: "Something went wrong",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });

      this.$vs.loading.close();

    },
    async updateCurrImg(input, index) {
      if (input.target.files && input.target.files[0]) {
        this.answers[index].story = input.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          this.answers[index].dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  mounted() {
    this.getChildStoriesPresets();
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem('child'));
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  },
}
</script>
<style lang="scss" scoped>
.story-form{
  max-width: 900px;
}
.story-img {
  max-width: 500px;
  overflow: hidden;
  img{
    width: 100%;
    height: auto;
  }
}
.story-instruction{
  margin-bottom: 15px;
  display: block;
}
</style>
