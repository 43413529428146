<template>
  <div>
    <vx-card v-if="showChildStoryList">
      <div class="md:flex">
        <h2>Your Child Story</h2>
        <div class="mt-5 md:mt-0 md:ml-auto">
          <vs-button
            color="#F0F0F0"
            class="mr-5 button-grey"
            type="filled"
            @click="showInviteContributorPopUp = true"
          >
            Invite Contributors
          </vs-button>
          <vs-button color="#289ad5" type="filled" @click="addForm">
            Add your stories
          </vs-button>
        </div>
      </div>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col mb-4">
        <div
          v-if="childStories.length > 0"
          v-for="story in childStories"
          :key="story._id"
          class="ph-img-list"
        >
          <a @click="selectChildStory(story)">
            <span :class="'status-tag ' + story.status">{{
              story.status
            }}</span>
            <img
              :src="
                getDisplayImage(story) ? getDisplayImage(story) : $defaultImage
              "
              class="responsive h-64 object-cover"
            />
          </a>
          <span class="mt-5 block" @click="selectChildStory(story)">
            By {{ story.filledBy.fullName }}</span
          >
          <div v-if="story.createdBy === parent._id" class="editinfo-btn">
            <feather-icon icon="TrashIcon" @click="deleteStory(story._id)" />
            <feather-icon icon="AlertCircleIcon" @click="editStory(story)" />
          </div>
        </div>
        <div v-if="childStories.length < 1">
          <span class="mt-5 block no-stories"> No stories yet</span>
        </div>
      </div>
    </vx-card>

    <!--Child story Detail template-->
    <parent-child-story-detail
      v-if="showChildStoryDetail"
      :selected-story="propSelectedChildStory"
      @showList="showList"
    ></parent-child-story-detail>
    <!--Child Story Add -->
    <parent-add-story
      v-if="showAddForm"
      @showList="showList"
    ></parent-add-story>
    <parent-edit-story
      v-if="showEditForm"
      :selected-story="selectedChildStory"
      @showList="showList"
    ></parent-edit-story>

    <vs-popup
      v-if="showInviteContributorPopUp"
      class="holamundo add-children-popup"
      title
      :active.sync="showInviteContributorPopUp"
    >
      <div class="p-10">
        <h2 class="h1 mb-6">Invite Contributors</h2>
        <form class="w-full md:w-1/1">
          <vs-row>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="firstName"
                name="firstName"
                v-validate="'required'"
                label-placeholder="First Name"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('firstName')"
                >{{ errors.first("firstName") }}</span
              >
            </vs-col>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="surName"
                name="surName"
                v-validate="'required'"
                label-placeholder="Surname"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('surName')"
                >{{ errors.first("surName") }}</span
              >
            </vs-col>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="relationToChild"
                name="relationToChild"
                v-validate="'required'"
                label-placeholder="Relation to Child"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('relationToChild')"
                >{{ errors.first("relationToChild") }}</span
              >
            </vs-col>
            <vs-col class="w-full input-wrap">
              <vs-input
                class="w-full mt-5"
                v-model="email"
                name="email"
                v-validate="'required|email'"
                label-placeholder="Email"
                data-vv-as="Email"
              />
              <span class="text-danger text-sm" v-show="errors.has('email')">{{
                errors.first("email")
              }}</span>
            </vs-col>
          </vs-row>
          <div class="flex">
            <vs-button
              @click="submitInviteForm"
              color="primary"
              class="mt-5 ml-auto"
              type="filled"
              >Send invitation
            </vs-button>
          </div>
        </form>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import ParentChildStoryDetail from "@/views/pages/parent/childStory/ParentChildStoryDetail";
import ParentAddStory from "@/views/pages/parent/childStory/ParentAddStory";
import ParentEditStory from "@/views/pages/parent/childStory/ParentEditStory";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

const dictionary = {
  en: {
    attributes: {
      fistName: "First Name",
      surName: "Surname",
      relationToChild: "Relation to Child"
    }
  }
};

Validator.localize(dictionary);

export default {
  components: { ParentChildStoryDetail, ParentAddStory, ParentEditStory },
  data() {
    return {
      showInviteContributorPopUp: false,
      firstName: "",
      surName: "",
      relationToChild: "",
      email: "",
      childStories: [],
      showChildStoryList: true,
      showChildStoryDetail: false,
      selectedChildStory: "",
      showAddForm: false,
      showEditForm: false,
      showAddFormButton: true,
      propSelectedChildStory: ""
    };
  },
  methods: {
    ...mapActions("childrenStories", [
      "fetchParentChildStoryById",
      "deleteChildStoryForParent"
    ]),
    ...mapActions("parent", ["inviteContributor"]),
    getDisplayImage(story) {
      const imageExistStory = _.filter(story.stories, { type: "image" });
      if (imageExistStory.length) {
        return imageExistStory[0].story;
      }
      return false;
    },
    editStory(story) {
      this.showChildStoryList = false;
      this.selectedChildStory = story;
      this.showEditForm = true;
    },
    async deleteStory(id) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async result => {
        if (result.value) {
          self.$vs.loading();
          await self
            .deleteChildStoryForParent(id)
            .then(response => {
              self.$vs.loading.close();
              self.$vs.notify({
                title: "Success",
                text: "Story has been deleted successfully",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
              self.getChildStories(self.childId);
            })
            .catch(err => {
              self.$vs.loading.close();

              self.$vs.notify({
                title: "Failure",
                text: "Something went wrong",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            });
        }
      });
    },
    showList() {
      this.getChildStories(this.childId);
      this.showChildStoryList = true;
      this.showChildStoryDetail = false;
      this.showAddForm = false;
      this.showEditForm = false;
      this.selectedChildStory = "";
    },
    addForm() {
      this.showChildStoryList = false;
      this.showChildStoryDetail = false;
      this.showAddForm = true;
    },
    async submitInviteForm() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            fullName: this.firstName + " " + this.surName,
            email: this.email,
            relationToChild: this.relationToChild,
            childId: this.$store.state.AppParentSelectedChild._id,
            learningCenterId: this.$store.state.AppParentSelectedChild
              .learningCenterId
            //storyId: this.relationToChild,
          };

          this.inviteContributor(obj)
            .then(res => {
              this.showInviteContributorPopUp = false;
              this.$vs.notify({
                title: "Success",
                text: "Contributor invited successfully.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
            })
            .catch(err => {
              this.$vs.notify({
                title: "Failure",
                text: "Something went wrong",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            });
        }
      });
    },
    async getChildStories(childId) {
      this.$vs.loading();
      await this.fetchParentChildStoryById(childId)
        .then(async response => {
          this.$vs.loading.close();
          this.childStories = await response.data.data;
          const parentChildStories = await _.find(this.childStories, [
            "createdBy",
            this.parent._id
          ]);
          this.showAddFormButton = !parentChildStories;

          if (this.$route.query.storyId && this.$route.query.storyId != "") {
            this.showChildStoryDetail = true;
            this.showChildStoryList = false;
            let selectedStory = _.find(this.childStories, [
              "_id",
              this.$route.query.storyId
            ]);
            this.selectedChildStory = selectedStory;
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    async selectChildStory(story) {
      this.showChildStoryList = false;
      this.selectedChildStory = story;
      this.propSelectedChildStory = story._id;
      this.showChildStoryDetail = true;
    }
  },

  watch: {
    async childId(val) {
      this.activeTabIndex = 0;
      this.getChildFromParentChildren(val);
    },
    showInviteContributorPopUp() {
      this.firstName = "";
      this.surName = "";
      this.relationToChild = "";
      this.email = "";
      this.errors.clear();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    }
  },
  mounted() {
    this.getChildStories(this.childId);
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem("child"));
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  }
};
</script>
<style lang="scss" scoped>
.button-grey.vs-button:hover {
  color: #000 !important;
}
</style>
