<template>
  <div>
    <div v-if="showNewsletterList">
      <vx-card>
        <h2>Newsletters</h2>
        <div
          class="flex flex-wrap ph-img-list-wrap ph-5-col"
          v-if="newsletters.length > 0"
        >
          <div
            v-for="newsletter in newsletters"
            :key="newsletter._id"
            class="ph-img-list"
          >
            <a @click="newsletterSelect(newsletter._id)">
              <img
                :src="
                  newsletter.photos.length
                    ? newsletter.photos[0].photo
                    : $defaultImage
                "
                class="responsive h-64 object-contain"
              />
            </a>
            <span
              class="mt-5 block"
              @click="newsletterSelect(newsletter._id)"
              >{{ newsletter.title }}</span
            >
          </div>
        </div>
        <div v-else>No Newsletter has been added</div>
        <div
          class="pagination-div flex justify-center float-none mt-5"
          v-if="newsletters.length > 0"
        >
          <paginate
            :page-count="totalPages"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
          ></paginate>
        </div>
      </vx-card>
    </div>
    <div v-if="showNewsletterDetail">
      <h2 class="mx-10" @click="listAll" style="cursor:pointer">Newsletters</h2>

      <vx-card style="margin-bottom: 30px">
        <div class="vx-breadcrumb mb-5 md:block hidden">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <a class="" @click="listAll"> Newsletters</a>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right w-4 h-4"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <a class="">{{ selectedNewsletter.title }}</a>
            </li>
          </ul>
        </div>
        <div class="w-full mt-10">
          <img
            :src="
              selectedNewsletter.centreDetails
                ? selectedNewsletter.centreDetails.logo
                : $defaultImage
            "
            :alt="
              selectedNewsletter.centreDetails
                ? selectedNewsletter.centreDetails.name
                : selectedNewsletter.title
            "
            style="height: auto; width: 150px; margin-bottom: 20px"
          />
          <h2>{{ selectedNewsletter.title }}</h2>
        </div>
        <div class="w-full mt-10">
          <p v-html="selectedNewsletter.introduction"></p>
        </div>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col mb-10">
          <div
            v-for="(photo, index) in selectedNewsletter.photos"
            :key="photo._id"
            class="ph-img-list"
          >
            <a href="javascript::void(0)" @click.prevent="openGallery(index, index, false)">
              <img :src="photo.photo" class="responsive h-64 object-contain" />
            </a>
          </div>
        </div>

        <template
          v-if="activitiesList.length"
          v-for="(activity, key) in activitiesList"
        >
          <div class="flex items-center mb-5 mt-10" :key="key + 0.1">
            <strong>
              <h3>{{ activity.name }}</h3>
            </strong>
          </div>
          <div class="w-full mt-10 mb-5" :key="key + 0.2">
            <ul class="flex">
              <li class="pr-5 md:pr-10 text-center">
                <strong>Core Skills</strong>
                <img
                  :src="
                    activity.coreSkills.icon
                      ? activity.coreSkills.icon
                      : $defaultImage
                  "
                  :alt="activity.coreSkills.name"
                  class="h-16 object-contain block mx-auto mt-5"
                />
                 <strong>{{ activity.coreSkills ? activity.coreSkills.name : '' }}</strong>
              </li>
              <li class="px-5 md:px-10 text-center">
                <strong>Ways of Learning</strong>
                <img
                  :src="
                    activity.waysOfLearnings.icon
                      ? activity.waysOfLearnings.icon
                      : $defaultImage
                  "
                  :alt="activity.waysOfLearnings.name"
                  class="h-16 object-contain block mx-auto mt-5"
                />
                <strong>{{ activity.waysOfLearnings ? activity.waysOfLearnings.name : '' }}</strong>
              </li>
              <li class="pl-5 md:pl-10 text-center">
                <strong>STREAM</strong>
                <img
                  :src="
                    activity.streams.icon
                      ? activity.streams.icon
                      : $defaultImage
                  "
                  :alt="activity.streams.name"
                  class="h-16 object-contain block mx-auto mt-5"
                />
                <strong>{{ activity.streams ? activity.streams.name : '' }}</strong>
              </li>
            </ul>
          </div>
          <p class="md" :key="key + 0.7">{{ activity.purposeStatement }}</p>

          <div
            class="flex flex-wrap ph-img-list-wrap ph-5-col"
            :key="key + 0.6"
            v-if="activity.photos.length"
          >
            <div
              v-for="(photo, index) in activity.photos"
              :key="photo._id"
              class="ph-img-list"
            >
              <a
                href="javascript::void(0)"
                @click.prevent="openGallery(index, key)"
              >
                <img
                  :src="photo.photo"
                  class="responsive h-64 object-contain"
                />
              </a>
            </div>
          </div>
        </template>
        <LightBox
          ref="lightbox"
          :media="media"
          :show-light-box="false"
          :show-caption="false"
          :show-thumbs="false"
          @onImageChanged="onImageChanged"
        />
      </vx-card>

      <vx-card class="mu-30">
        <div v-html="selectedNewsletter.existText"></div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

export default {
  components: { LightBox },
  data() {
    return {
      itemPerPage: 15,
      page: 1,
      totalPages: 1,
      newsletters: [],
      selectedNewsletter: "",
      showNewsletterDetail: false,
      showNewsletterList: true,
      activitiesList: [],
      currentImageIndex: 0,
      media: []
    };
  },
  methods: {
    ...mapActions("newsletter", [
      "fetchNewsletterByRoomIdAndParentId",
      "fetchNewsletterDetailById"
    ]),
    moment(date) {
      return moment(date).format("DD/MM/YY");
    },
    listAll() {
      this.showNewsletterList = true;
      this.showNewsletterDetail = false;
    },
    async getNewsletter(roomId, parentId, page) {
      this.$vs.loading();
      const obj = {
        roomId: roomId,
        parentId: parentId,
        page: page,
        limit: this.itemPerPage
      };
      this.fetchNewsletterByRoomIdAndParentId(obj).then(response => {
        this.$vs.loading.close();
        this.newsletters = response.data.data.docs;
        this.totalPages = Math.ceil(
          response.data.data.pagination.total / this.itemPerPage
        );
      });
      this.$vs.loading.close();
    },
    async newsletterSelect(id) {
      this.fetchNewsletterDetailById(id)
        .then(response => {
          this.selectedNewsletter = response.data.data;
          if(this.selectedNewsletter.photos && this.selectedNewsletter.photos.length > 0){
            let media = this.selectedNewsletter.photos.map(photo => {
                  return {
                    id: photo._id,
                    thumb: photo.photo,
                    src: photo.photo
                  };
                });
                this.selectedNewsletter.media = media
          }
          this.activitiesList =
            this.selectedNewsletter.activitiesList.length &&
            "name" in this.selectedNewsletter.activitiesList[0]
              ? this.selectedNewsletter.activitiesList
              : [];
          if (this.activitiesList.length > 0) {
            this.activitiesList = this.activitiesList.map(activities => {
              if (activities.photos.length > 0) {
                let media = activities.photos.map(photo => {
                  return {
                    id: photo._id,
                    thumb: photo.photo,
                    src: photo.photo
                  };
                });

                return { ...activities, media };
              }
              return { ...activities };
            });
            this.media = this.activitiesList[0].media;
          }
          this.showNewsletterList = false;
          this.showNewsletterDetail = true;
        })
        .catch(error => console.log(error));
    },
    handleChangePage(page) {
      this.getNewsletter(this.child.roomId, this.parent._id, page);
    },
    onImageChanged(index) {
      this.currentImageIndex = index;
    },
    openGallery(index, key, activities=true) {
      this.media = activities ? this.activitiesList[key].media : this.selectedNewsletter.media;
      this.$refs.lightbox.showImage(index);
      this.currentImageIndex = index;
    }
  },
  mounted() {
    this.getNewsletter(this.child.roomId, this.parent._id, this.page);

    if (
      "undefined" != typeof this.$route.query.newsletterId &&
      "" != this.$route.query.newsletterId
    ) {
      this.newsletterSelect(this.$route.query.newsletterId);
    }
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem("child"));
    },
    children() {
      return localStorage.getItem("parentChildren");
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  }
};
</script>
