<template>
  <div id="demo-basic-card">
    <div id="page-user-view">
      <div id="user-data">

        <vx-card class="mb-base m-0 rounded-none shadow-none pb-8">
          <!-- Avatar -->
          <div class="vx-row" style="align-items: center;">

            <!-- Avatar Col -->
            <vs-avatar size="130px"
                       :src="childrenData.photo ? childrenData.photo : $defaultImage"/>

            <!-- Information - Col 1 -->
            <div class="vx-col flex-1" id="account-info-col-1">
              <h2 class="mb-1">{{ childrenData.fullName ? childrenData.fullName : '' }}</h2>
              <span class="mb-1"><strong>Age group:</strong> {{
                  childrenData.ageDetails ? childrenData.ageDetails.ageGroup : ''
                }} </span><br>
              <span class="child-user-info"> <strong>Class:</strong> {{
                  childrenData.room ? childrenData.room.name : ''
                }}</span>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <vs-popup
        title="Hi Parents / Guardians,"
        class="fixed-height"
        :active.sync="childStoryEmptyPopUp"
      >
       <p>The Childs Story is a handful of questions to help our educators strengthen the partnership with families. Taking a few moments to answer the questions empowers our educators to really understand your child.
        <br>
        <br>Thanks for your support. </p>
       <vs-button @click="goToChildStory" class="mt-5">Go to child story</vs-button>
      </vs-popup>
    <!--Tabs -->
    <div class="mt-5 tab-wrapper">

      <vs-tabs class="activity-tab" v-model="activeTabIndex">
        <vs-tab label="Profile">
          <div>
            <child-profile></child-profile>
          </div>
        </vs-tab>
        <vs-tab label="Learning Stories">
          <div>
            <parent-learning-stories></parent-learning-stories>
          </div>
        </vs-tab>
        <vs-tab label="Newsletters">
          <div>
            <parent-news-letter></parent-news-letter>
          </div>
        </vs-tab>
        <vs-tab label="Your Childs story">
          <div>
            <parent-child-story></parent-child-story>
          </div>
        </vs-tab>
        <vs-tab label="Activity Log" v-if="dailyDairyPermisson">
          <div>
            <parent-daily-diary></parent-daily-diary>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import ChildProfile from "@/views/pages/parent/ChildProfile";
import ParentLearningStories from "@/views/pages/parent/learningStory/ParentLearningStories";
import ParentNewsLetter from "@/views/pages/parent/newsletter/ParentNewsLetter";
import ParentDailyDiary from "@/views/pages/parent/dailyDiary/ParentDailyDiary";
import ParentChildStory from "@/views/pages/parent/childStory/ParentChildStory";
import Vue from 'vue'
import VueLodash from 'vue-lodash'
import _ from 'lodash'
import { mapActions } from "vuex";

import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import axios from "../../../http/axios/index.js";

Vue.use(VueLodash, {name: 'custom', lodash: _})

export default {
  components: {ChildProfile, ParentLearningStories, ParentNewsLetter, ParentDailyDiary, ParentChildStory},
  data() {
    return {
      activeTabIndex: 0,
      dailyDairyPermisson: false,
      childStoryEmptyPopUp: false,
      childrenData: localStorage.getItem('child'),
    };
  },
  methods: {
    ...mapActions("childrenStories", ["fetchParentChildStoryById"]),
     async resolveCheckUserPermission(userId, featureSlug) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        "/user/check-user-permission-for-module/" + userId + "/" + featureSlug
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
},
    async getChildFromParentChildren(id) {
      await this.fetchParentChildStoryById(id)
        .then(async response => {
          if (response.data.data.length == 0) {
            this.childStoryEmptyPopUp = true;
          }
        }).catch(err => {
          console.log(err);
        });
      let a = JSON.parse(this.parentChildren);
      this.childrenData = _.find(a, {'_id': id})
      localStorage.setItem('child', JSON.stringify(this.childrenData));
      this.$store.dispatch('updateParentSelectedChild');
    },
    goToChildStory() {
      this.activeTabIndex= 3;
      this.childStoryEmptyPopUp= false;
    }
  },
  watch: {
    activeTabIndex() {
      let query = Object.assign({}, this.$route.query);

      //for story page
      if (this.activeTabIndex !== 3) {
        delete query.storyId;
      }
      if (this.activeTabIndex !== 2) {
        delete query.newsletterId;
      }
      delete query.tab;
      this.$router.replace({query});

    },
    async childId(val) {
      this.activeTabIndex = 0;
      this.getChildFromParentChildren(val);
    },

    "$route.query.tab": function () {

      if ("undefined" != typeof this.$route.query.tab && '' !== this.$route.query.tab) {
        if (this.$route.query.tab === "newsletters") {
          this.activeTabIndex = 2;
        }
        if (this.$route.query.tab === "child-story") {
          this.activeTabIndex = 3;
        }
      }
    }

  },
  mounted() {
    this.getChildFromParentChildren(this.childId);
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem('child'));
    },
    parentChildren() {
      return localStorage.getItem('parentChildren');
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  },
  async beforeMount() {
      try{
      await this.resolveCheckUserPermission(
            this.$store.state.AppActiveUser._id,
            'daily_diary_of_children_play'
          )

          this.dailyDairyPermisson = true;
      }catch(error){

      if(error.response.status == 403){
        this.dailyDairyPermisson = false;
      }else{
        this.dailyDairyPermisson = true;
      }

      }
    },
  created() {
    if ("undefined" != typeof this.$route.query.tab && '' !== this.$route.query.tab) {
      if (this.$route.query.tab === "newsletters") {
        this.activeTabIndex = 2;
      }
      if (this.$route.query.tab === "child-story") {
        this.activeTabIndex = 3;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.flex-100 {
  flex: 0 0 100%;
}

.fixed-height .vs-popup{
  height: 200px
}
</style>
