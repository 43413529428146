var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showNewsletterList)?_c('div',[_c('vx-card',[_c('h2',[_vm._v("Newsletters")]),(_vm.newsletters.length > 0)?_c('div',{staticClass:"flex flex-wrap ph-img-list-wrap ph-5-col"},_vm._l((_vm.newsletters),function(newsletter){return _c('div',{key:newsletter._id,staticClass:"ph-img-list"},[_c('a',{on:{"click":function($event){return _vm.newsletterSelect(newsletter._id)}}},[_c('img',{staticClass:"responsive h-64 object-contain",attrs:{"src":newsletter.photos.length
                  ? newsletter.photos[0].photo
                  : _vm.$defaultImage}})]),_c('span',{staticClass:"mt-5 block",on:{"click":function($event){return _vm.newsletterSelect(newsletter._id)}}},[_vm._v(_vm._s(newsletter.title))])])}),0):_c('div',[_vm._v("No Newsletter has been added")]),(_vm.newsletters.length > 0)?_c('div',{staticClass:"pagination-div flex justify-center float-none mt-5"},[_c('paginate',{staticClass:"pagination",attrs:{"page-count":_vm.totalPages,"click-handler":_vm.handleChangePage,"page-range":9}})],1):_vm._e()])],1):_vm._e(),(_vm.showNewsletterDetail)?_c('div',[_c('h2',{staticClass:"mx-10",staticStyle:{"cursor":"pointer"},on:{"click":_vm.listAll}},[_vm._v("Newsletters")]),_c('vx-card',{staticStyle:{"margin-bottom":"30px"}},[_c('div',{staticClass:"vx-breadcrumb mb-5 md:block hidden"},[_c('ul',{staticClass:"flex flex-wrap items-center"},[_c('li',{staticClass:"inline-flex items-end"},[_c('a',{on:{"click":_vm.listAll}},[_vm._v(" Newsletters")]),_c('span',{staticClass:"breadcrumb-separator mx-2"},[_c('span',{staticClass:"feather-icon select-none relative"},[_c('svg',{staticClass:"feather feather-chevrons-right w-4 h-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24px","height":"24px","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"13 17 18 12 13 7"}}),_c('polyline',{attrs:{"points":"6 17 11 12 6 7"}})])])])]),_c('li',{staticClass:"inline-flex items-center"},[_c('a',{},[_vm._v(_vm._s(_vm.selectedNewsletter.title))])])])]),_c('div',{staticClass:"w-full mt-10"},[_c('img',{staticStyle:{"height":"auto","width":"150px","margin-bottom":"20px"},attrs:{"src":_vm.selectedNewsletter.centreDetails
              ? _vm.selectedNewsletter.centreDetails.logo
              : _vm.$defaultImage,"alt":_vm.selectedNewsletter.centreDetails
              ? _vm.selectedNewsletter.centreDetails.name
              : _vm.selectedNewsletter.title}}),_c('h2',[_vm._v(_vm._s(_vm.selectedNewsletter.title))])]),_c('div',{staticClass:"w-full mt-10"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.selectedNewsletter.introduction)}})]),_c('div',{staticClass:"flex flex-wrap ph-img-list-wrap ph-5-col mb-10"},_vm._l((_vm.selectedNewsletter.photos),function(photo,index){return _c('div',{key:photo._id,staticClass:"ph-img-list"},[_c('a',{attrs:{"href":"javascript::void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.openGallery(index, index, false)}}},[_c('img',{staticClass:"responsive h-64 object-contain",attrs:{"src":photo.photo}})])])}),0),_vm._l((_vm.activitiesList),function(activity,key){return (_vm.activitiesList.length)?[_c('div',{key:key + 0.1,staticClass:"flex items-center mb-5 mt-10"},[_c('strong',[_c('h3',[_vm._v(_vm._s(activity.name))])])]),_c('div',{key:key + 0.2,staticClass:"w-full mt-10 mb-5"},[_c('ul',{staticClass:"flex"},[_c('li',{staticClass:"pr-5 md:pr-10 text-center"},[_c('strong',[_vm._v("Core Skills")]),_c('img',{staticClass:"h-16 object-contain block mx-auto mt-5",attrs:{"src":activity.coreSkills.icon
                    ? activity.coreSkills.icon
                    : _vm.$defaultImage,"alt":activity.coreSkills.name}}),_c('strong',[_vm._v(_vm._s(activity.coreSkills ? activity.coreSkills.name : ''))])]),_c('li',{staticClass:"px-5 md:px-10 text-center"},[_c('strong',[_vm._v("Ways of Learning")]),_c('img',{staticClass:"h-16 object-contain block mx-auto mt-5",attrs:{"src":activity.waysOfLearnings.icon
                    ? activity.waysOfLearnings.icon
                    : _vm.$defaultImage,"alt":activity.waysOfLearnings.name}}),_c('strong',[_vm._v(_vm._s(activity.waysOfLearnings ? activity.waysOfLearnings.name : ''))])]),_c('li',{staticClass:"pl-5 md:pl-10 text-center"},[_c('strong',[_vm._v("STREAM")]),_c('img',{staticClass:"h-16 object-contain block mx-auto mt-5",attrs:{"src":activity.streams.icon
                    ? activity.streams.icon
                    : _vm.$defaultImage,"alt":activity.streams.name}}),_c('strong',[_vm._v(_vm._s(activity.streams ? activity.streams.name : ''))])])])]),_c('p',{key:key + 0.7,staticClass:"md"},[_vm._v(_vm._s(activity.purposeStatement))]),(activity.photos.length)?_c('div',{key:key + 0.6,staticClass:"flex flex-wrap ph-img-list-wrap ph-5-col"},_vm._l((activity.photos),function(photo,index){return _c('div',{key:photo._id,staticClass:"ph-img-list"},[_c('a',{attrs:{"href":"javascript::void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.openGallery(index, key)}}},[_c('img',{staticClass:"responsive h-64 object-contain",attrs:{"src":photo.photo}})])])}),0):_vm._e()]:_vm._e()}),_c('LightBox',{ref:"lightbox",attrs:{"media":_vm.media,"show-light-box":false,"show-caption":false,"show-thumbs":false},on:{"onImageChanged":_vm.onImageChanged}})],2),_c('vx-card',{staticClass:"mu-30"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectedNewsletter.existText)}})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }